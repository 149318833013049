
.App{
  background-image: url(./assets/c1.jpg);
  background-repeat: no-repeat;
  background-attachment: fixed;
}


@media (prefers-reduced-motion: no-preference) {
  .globe {
    animation: App-logo-spin infinite 5s ;
    animation-direction: alternate;
  }
}



.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(20deg);
  }

}



/* logo Section -------------------------------------------------------------------------------------------------------------- */

.logo{
 
  max-height:2.5rem;
  margin-left: 0.25rem;
}
.globe{
 
  max-height:2.5rem
  
}

.App-logo {
 padding-bottom: 0.5rem;

}
.header-row{
 
  margin-top: 0.5rem;
  background-color: white;
 
}

.main-buttons-register{
  padding-top: 0.8rem;
} 

.gif{
  width: 100%;
  padding-top: 0.8rem;
}


/* Main Buttons------------------------------------------------------------------------------------------------------------------ */

/* .main-buttons {
  text-align: center;
 
  
  
} */
.header-row-butons{
  background-color: black;
}
/* Button{
  width:100%;
  
} */
Button p{
  font-size: 0.9rem;
}






/* Register----------------------------------------------------------------------------------------------------------------------------------------------------------------------- */

.register-middle{
  
  background-color: rgb(255, 236, 211);
  border: 2px black solid;
  padding: 20px
}

.registerDivs{
  margin-top:30px
}

.step4{
margin-top: 30px;
}
/* Search form--------------------------------------------------------------------------------------------------------------------------------------------------------------------- */

/* .searchForm{
  
  padding:20px;
  background-color: cornsilk;
  border: 1px black solid;
  margin-top: 5rem;
} */
.searchLeft{
  padding-top: 20px;
  min-height: 100vh;
  width: 100vw;
  
  
}

.searchLeftMain{
  margin-top: 15vh;
  text-align: center;
}

.search-result-box{
  margin-top: 5rem;
  height: 55rem;
  overflow: scroll;
  width:100%;
 
}

.search-text{
  text-align: center;
  color:white;
  margin-bottom: 5rem;
}
.search-text-find{
  font-size: 3rem;
  text-align: center;

}
.search-text-enter{
  font-size: 1.5rem;
  text-align: center;
}

/* Card--------------------------------------------------------------------------------------------------------------------------------------------------------------------- */
.result-card{
  border: 3px #2a6ebb solid;
  margin-bottom: 2rem;
  /* background-color: rgba(242,113,28,0.4) */
  background-color: rgb(255, 255, 255);
  padding:1rem;
  border-radius: 5px;
  
}


.card-churchArea{
  background-color: rgb(255,133,27) ;
  border-radius: 5px;
  padding:0.25rem;
  text-align: center;
  margin-bottom: 5px;
  margin-top: 10px;
  
  
}
/* .card-churchArea h1{
  font-size: 1.5vw;
} */
.card-icon{
  width:100%;
  background-color: white;
  border-radius: 5px;
  border: 2px #2a6ebb solid;
  

}

.card-icon-text{
  text-align: center;
  background-color: rgb(42,110,187);
  color:white;
  
}

/* .card-icon-text h3{
  font-size: ;
} */
.card-churchAddress{
  text-align: center;
  margin-top: 10px;
}

/* Login form----------------------------------------------------------------------------------------------------------------------------------------------------- */

.loginForm{
  margin-top: 5rem;
}
.eachRow{
  margin-top: 15px;
}